/**
 * Module pour gérer le comportement sticky du menu d'inventaire
 * Assure que les onglets restent visibles pendant le défilement de la page
 */

/**
 * Initialise le comportement sticky du menu d'inventaire
 */
export const initializeStickyMenuTabs = () => {
    if (!document.documentElement.classList.contains('v4')) {
        return;
    }
    
    // Vérifier si on est sur la page inventaire
    if (!window.location.href.includes('menu-inventaire')) {
        return;
    }
    
    const inventoryMenu = document.querySelector('.tabs-buttons');
    if (!inventoryMenu) {
        return;
    }
    
    // Position initiale du menu pour détecter quand il devient sticky
    const menuOriginalTop = inventoryMenu.getBoundingClientRect().top + window.scrollY;
    
    // Fonction pour vérifier si le menu doit devenir sticky
    const checkStickyState = () => {
        if (window.scrollY > menuOriginalTop - 79) {
            inventoryMenu.classList.add('sticky');
        } else {
            inventoryMenu.classList.remove('sticky');
        }
    };
    
    // Écouter l'événement de défilement pour ajuster l'état sticky
    window.addEventListener('scroll', checkStickyState);
    
    // Vérifier l'état initial
    checkStickyState();
}; 